
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class tjfqsh extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  formPage = {
    name: '',
    type: 0,
    obligee: '',
    members: '',
    protocol_person: '',
    institute: '',
    source: '',
    nature: '',
    granted: 0,
    accept: 0,
    plan: 0,
    is_accessory: 0,
    accessories: '',
    phone: '',
    distribution: '',
    is_deputy: '',
    person_list: '',
    number: '',
    complete_person: '',
    prospect: '',
    target_customer: '',
    transform_way: '',
    expected_price: 0,
    bottom_price: 0,
    abstract: '',
    technical_maturity: '',
    economic_benefit: '',
    social_benefit: '',
    risk_assessment: '',
    known_condition: '',
    other: ''

  }

rule_formPage = {

}

form_submit() {
  this.$refs.ref_formPage.validate(valid => {
    if (valid) {
      const postData = {
        name: this.formPage.name,
        type: this.formPage.type,
        obligee: this.formPage.obligee,
        members: this.formPage.members,
        protocol_person: this.formPage.protocol_person,
        institute: this.formPage.institute,
        source: this.formPage.source,
        nature: this.formPage.nature,
        granted: this.formPage.granted,
        accept: this.formPage.accept,
        plan: this.formPage.plan,
        is_accessory: this.formPage.is_accessory,
        accessories: this.formPage.accessories,
        phone: this.formPage.phone,
        distribution: this.formPage.distribution,
        is_deputy: this.formPage.is_deputy,
        person_list: this.formPage.person_list,
        number: this.formPage.number,
        complete_person: this.formPage.complete_person,
        prospect: this.formPage.prospect,
        target_customer: this.formPage.target_customer,
        transform_way: this.formPage.transform_way,
        expected_price: this.formPage.expected_price,
        bottom_price: this.formPage.bottom_price,
        abstract: this.formPage.abstract,
        technical_maturity: this.formPage.technical_maturity,
        economic_benefit: this.formPage.economic_benefit,
        social_benefit: this.formPage.social_benefit,
        risk_assessment: this.formPage.risk_assessment,
        known_condition: this.formPage.known_condition,
        other: this.formPage.other

      }
      Api.http_tjfqsh_f_formPage(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
f_init() {
  this.get_formPage()
}

get_formPage() {
  const postData = {
    id: this.$route.query.id
  }
  Api.http_tjfqsh_f_get_formPage(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.formPage = res.data
    }
  })
}
created() {
  this.f_init();
}
}
